import {
    GET_LICENSES,
    GET_LICENSE_DETAILS,
    SORT_LICENSES,
    FILTER_LICENSES
} from '../actions';
import { LOADING } from '../../global/actions'
import moment from 'moment';
import { getNestedValueFromString } from '../../global/reducer';

// TODO GLOBAL
const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
    },
};

function licenseReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
        console.log("reducer action", action);
    };
    switch (action.type) {
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;
    switch (action.type) {
        case GET_LICENSES:
            state = Object.assign({}, state, { licenses: action.results, filteredLicenses: action.results });
            return state;
        case GET_LICENSE_DETAILS:
            if (!state.licenses) {
                state.licenses = [];
            }
            if (action.results && action.results.length) {
                action.results.forEach(updatedLicense => {
                    const existingLicenseIndex = state.licenses.findIndex(license => license.license_id === updatedLicense.license_id);
        
                    if (existingLicenseIndex !== -1) {
                        const updatedEntitlements = updatedLicense.entitlements || [];
                        const existingLicense = state.licenses[existingLicenseIndex];
                        
                        const newUpdatedLicense = {
                            ...existingLicense,
                            ...updatedLicense,
                            entitlements: updatedEntitlements
                        };
        
                        state.licenses[existingLicenseIndex] = newUpdatedLicense;
                    } else {
                        state.licenses.push(updatedLicense);
                    }
                });
            }
            state = { ...state, loading: false };
            return state;
        case FILTER_LICENSES:
            if (!state.licenses) {
                state.licenses = [];
            };
            let filterLicenseParams = action.data;
            let filteredLicenses = [];

            let newLicenses = state.licenses.filter(l => {
                if (filterLicenseParams?.licenseId?.length > 0) {
                    for (let licenseId of filterLicenseParams?.licenseId) {
                        if (!l?.license_id.startsWith(licenseId)) {
                            return false;
                        };
                    };
                }
                if (filterLicenseParams?.businessPartner?.length > 0) {
                    for (let businessPartner of filterLicenseParams?.businessPartner) {
                        if (!l?.business_partner.startsWith(businessPartner)) {
                            return false;
                        };
                    };
                }
                if (filterLicenseParams?.endUser?.length > 0) {
                    for (let endUser of filterLicenseParams?.endUser) {
                        if (!l?.end_user.startsWith(endUser)) {
                            return false;
                        };
                    };
                }
                if (filterLicenseParams?.order?.length > 0) {
                    for (let order of filterLicenseParams?.order) {
                        if (!l?.order.startsWith(order)) {
                            return false;
                        };
                    };

                }
                if (filterLicenseParams?.support_partner?.length > 0) {
                    for (let supportPartner of filterLicenseParams?.support_partner) {
                        if (!l?.support_partner.startsWith(supportPartner)) {
                            return false;
                        };
                    };
                }
                if (filterLicenseParams?.filterCreationDateStart) {
                    if (!moment(filterLicenseParams.filterCreationDateStart).isBefore(moment(l.creation_date))) {
                        return false
                    }
                };
                if (filterLicenseParams?.filterCreationDateEnd) {
                    if (!moment(filterLicenseParams.filterCreationDateEnd).isAfter(moment(l.creation_date))) {
                        return false
                    }
                };
                if (filterLicenseParams?.filterModificationDateStart) {
                    if (!moment(filterLicenseParams.filterModificationDateStart).isBefore(moment(l.modification_date))) {
                        return false
                    }
                };
                if (filterLicenseParams?.filterModificationDateEnd) {
                    if (!moment(filterLicenseParams.filterModificationDateEnd).isAfter(moment(l.modification_date))) {
                        return false
                    }
                };
                return true
            });
            filteredLicenses = filteredLicenses.concat(newLicenses);

            state = Object.assign({}, state, { filteredLicenses });
            return state;
        case SORT_LICENSES:
            const licenseParam = action.data.sortParameter;
            const licenseDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredLicenses?.sort(function (a, b) {
                const valueA = getNestedValueFromString(a, licenseParam);
                const valueB = getNestedValueFromString(b, licenseParam);

                if (typeof valueA[0] === 'number' && typeof valueB[0] === 'number') {
                    if (licenseDirection === 'ascending') {
                        return valueA - valueB;
                    } else {
                        return valueB - valueA;
                    }
                } else {
                    const textA = valueA?.toString()?.toUpperCase();
                    const textB = valueB?.toString()?.toUpperCase();

                    if (licenseDirection === 'ascending') {
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    } else {
                        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                    }
                }
            });
            return state;
        default:
            return state;
    }
}

export default licenseReducer

