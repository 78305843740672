import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import Login from './login';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Auth, Hub } from "aws-amplify";
import { Modal, CircularProgress } from '@mui/material';
import Dashboard from './dashboard/Dashboard';
import { setUserSession } from './redux/global/actions';
import Header from '@datalake/towercontrol-banner';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#6b469e',
    },
    secondary: {
      main: '#f50057',
    },
  },
});






function App() {

  const headerRef = useRef();
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errors);
  const userSession = useSelector((state) => state.userSession)

  return (
    <div className="App">

      <ThemeProvider theme={theme}>
        <Modal
          open={loading}
          style={{
            textAlign: "center",
            paddingTop: "45vh"
          }}
        >
          <CircularProgress />
        </Modal>
        <Header ref={headerRef}/>
        <header className="App-header" style={{ alignItems: 'stretch' }}>
          {userSession? <Dashboard headerRef={headerRef}/> : <Login />}
        </header>
      </ThemeProvider>
    </div>
  );
};

export default App;
