import { createStore, applyMiddleware, combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import reduceReducers from 'reduce-reducers';

import rootReducer from "../global/reducer/";
import applicationReducer from "../application/reducer";
import contractReducer from "../contract/reducer";
import dynamoReducer from "../dynamo_db/reducer";
import materialReducer from "../material/reducer";
import materialPlaceholderReducer from "../material_placeholder/reducer";
import systemReducer from "../system/reducer";
import licenseReducer from "../license/reducer";

import { middleware } from "../global/middleware";
import { materialMiddleware } from "../material/middleware";
import { dynamoMiddleware } from "../dynamo_db/middleware";
import { materialPlaceholderMiddleware } from "../material_placeholder/middleware";
import { applicationMiddleware } from "../application/middleware";
import { systemMiddleware } from "../system/middleware";
import { contractMiddleware } from "../contract/middleware";
import { licenseMiddleware } from "../license/middleware"

const allReducers = reduceReducers(
    rootReducer,
    applicationReducer,
    contractReducer,
    dynamoReducer,
    materialReducer,
    materialPlaceholderReducer,
    systemReducer,
    licenseReducer
  );
  
  const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
    },
    materials: [],
    filteredMaterials: []
  };
  
  const store = createStore(
    allReducers,
    initialState,
    applyMiddleware(
      middleware,
      materialMiddleware,
      dynamoMiddleware,
      materialPlaceholderMiddleware,
      applicationMiddleware,
      systemMiddleware,
      contractMiddleware,
      licenseMiddleware
    )
  );
  
  export default store;