import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InventoryIcon from '@mui/icons-material/Inventory';
import GavelIcon from '@mui/icons-material/Gavel';
import StorageIcon from '@mui/icons-material/Storage';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DataArrayIcon from '@mui/icons-material/DataArray';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import BadgeIcon from '@mui/icons-material/Badge';
import {
  useNavigate,
  Navigate
} from "react-router-dom";
import { Tooltip } from '@mui/material';
import getUserProfiles from '../tokenUtils'
import Release from './Release';



export default function MainListItems() {

  const navigate = useNavigate();
  let profiles = getUserProfiles();
  if (!profiles) {
    // No matching item found
    return <Navigate to="/fallback" replace />;
  }


  return (<React.Fragment>
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Materials'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/materials')}
        >
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Materials" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Contracts'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/contracts')}
        >
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText primary="Contracts" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Orders'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/orders')}
        >
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Placeholder Materials'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/placeholdermaterials')}
        >
          <ListItemIcon>
            <DataObjectIcon />
          </ListItemIcon>
          <ListItemText primary="Placeholder Materials" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Serial Numbers'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/serialnumbers')}
        >
          <ListItemIcon>
            <DataArrayIcon />
          </ListItemIcon>
          <ListItemText primary="Serial Numbers" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Systems'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/systems')}
        >
          <ListItemIcon>
            <WysiwygIcon />
          </ListItemIcon>
          <ListItemText primary="Systems" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='System Applications'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/systemapplications')}
        >
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          <ListItemText primary="System Applications" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Licenses'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/licenses')}
        >
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          <ListItemText primary="Licenses" />
        </ListItemButton>
      </Tooltip>
    )}
    {(profiles.includes('vic-qa-admin') || profiles.includes('vic-qa-user') || profiles.includes('vic-prod-admin') || profiles.includes('vic-prod-user')) && (
      <Tooltip
        title='Data Quality Sap Orders'
        placement='right'
      >
        <ListItemButton
          onClick={() => navigate('/dataqualitysaporders')}
        >
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          <ListItemText primary="Data Quality Sap Orders" />
        </ListItemButton>
      </Tooltip>
    )}
  </React.Fragment>
  );
}
