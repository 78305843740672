import {
    GET_ORDERS,
    FILTER_ORDERS,
    SORT_ORDERS,
    FILTER_SERIAL_NUMBERS,
    GET_SERIAL_NUMBERS,
    SORT_SERIAL_NUMBERS,
    EXPORT_SERIAL_NUMBERS_TO_CSV,
    EXPORT_ORDERS_TO_CSV,
    FILTER_DATA_QUALITY_SAP_ORDERS,
    SORT_DATA_QUALITY_SAP_ORDERS,
    GET_DATA_QUALITY_SAP_ORDERS,
    GET_DATA_QUALITY_SAP_ORDERS_BULK, EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV,
} from '../actions';
import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import * as queries from '../../../graphql/queries.js';
import {restfulResources, getFromServer, parseDynamoResult} from '../../global/middleware';

let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

let result;
let questObject;
let getDynamoResult;
let parsedResults;
let getResult;
let ordersToExport;
let dataQualityOrdersToExport;
let headers;

export function dynamoMiddleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            switch (action.type){
                case FILTER_ORDERS:
                    break;
                case SORT_SERIAL_NUMBERS:
                    break;
                case SORT_DATA_QUALITY_SAP_ORDERS:
                    break;
                case SORT_ORDERS:
                    break;

                case GET_ORDERS:
                    result = [];
                    if (action.data?.order_id) {
                        questObject = {
                            resource: 'order',
                            params: { order_id: action.data.order_id }
                        };
                        if (action.data?.order_line) {
                            questObject.params.order_line = action.data.order_line
                        }
                        getDynamoResult = await getFromServer(questObject);
                        if (getDynamoResult?.results?.length > 0) {
                            action.orders = getDynamoResult.results;
                        } else {
                            action.errors = { responseCode: getDynamoResult.responseCode };
                            console.log('action.errors', action.errors, 'getResult', getDynamoResult);
                        };
                    };
                    break;
                case GET_DATA_QUALITY_SAP_ORDERS:
                    result = [];
                    if (action.data?.creation_date_from && action.data?.creation_date_to) {
                        questObject = {
                            resource: 'dataQualitySapOrders',
                            params: {
                                        creation_date_from: action.data.creation_date_from,
                                        creation_date_to:action.data.creation_date_to}
                        };
                        if (action.data?.order_id) {
                            questObject.params.order_id = action.data.order_id
                        }
                        if (action.data?.status) {
                            questObject.params.status = action.data.status
                        }
                        if (action.data?.sold_to) {
                            questObject.params.sold_to = action.data.sold_to
                        }
                        if (action.data?.part_number) {
                            questObject.params.part_number = action.data.part_number
                        }
                        getDynamoResult = await getFromServer(questObject);
                        if (getDynamoResult.responseCode < 202 && getDynamoResult !== 0) {
                            parsedResults = []
                            for (result of getDynamoResult.results[0]) {
                                parsedResults.push(parseDynamoResult(result))
                            }
                            action.dataQualitySapOrders = parsedResults;

                        } else {
                            action.errors = { responseCode: getDynamoResult.responseCode };
                            console.log('action.errors', action.errors, 'getResult', getDynamoResult);
                        };
                    };
                    break;
                case GET_DATA_QUALITY_SAP_ORDERS_BULK:
                    questObject = {};
                    questObject = {
                        resource: 'dataQualitySapOrders',
                        params: {
                            creation_date_from: action.data.creation_date_from,
                            creation_date_to:action.data.creation_date_to,
                            is_bulk: action.data.is_bulk
                        }
                    };
                    if (action.data?.order_id) {
                        questObject.params.order_id = action.data.order_id
                    }
                    if (action.data?.status) {
                        questObject.params.status = action.data.status
                    }
                    if (action.data?.sold_to) {
                        questObject.params.sold_to = action.data.sold_to
                    }
                    if (action.data?.part_number) {
                        questObject.params.part_number = action.data.part_number
                    }

                    let bulkResult = await getFromServer(questObject);
                    let url = bulkResult?.url;

                    if (bulkResult.responseCode >= 400)
                    {
                        action.errors = { responseCode: bulkResult.responseCode , error: bulkResult.error};
                        break;
                    }
                    let bulkResponse = await fetch(url, {
                        retries: 60,
                        retryDelay: 3000,
                        retryOn: function (attempt, error, response) {
                            // retry on any network error, or 4xx or 5xx status codes
                            if (error !== null || response.status >= 400) {
                                console.log(`retrying, attempt number ${attempt + 1}`);
                                return true;
                            }
                        }
                    }).catch(err => console.log('err', err))
                      .then(async response => {
                          const text = await response.text();
                          return {
                              response,
                              text
                          };
                      });
                    let jsonParsedResponse = JSON.parse(bulkResponse.text);
                    parsedResults = []
                    for (result of jsonParsedResponse[0]) {
                        parsedResults.push(parseDynamoResult(result))
                    }

                    action.bulkResponse = parsedResults;
                    break;
                case GET_SERIAL_NUMBERS:
                    console.log('GET_SERIAL_NUMBERS', action);

                    questObject = { params: {} };

                    if (action.data?.serial_number) {
                        questObject.params.serial_number = action.data?.serial_number
                    }
                    if (action.data?.bundle_reference) {
                        questObject.params.GSI1PK = action.data?.bundle_reference
                    }
                    questObject.resource = 'serialNumber';

                    getResult = await getFromServer(questObject);
                    if (getResult.responseCode < 202 && getResult !== 0) {
                        parsedResults = []
                        for (result of getResult.results) {
                            parsedResults.push(parseDynamoResult(result))
                        }
                        action.serialNumbers = parsedResults;
                    } else {
                        action.errors = { responseCode: getResult.responseCode };
                        console.log('action.errors', action.errors, 'getResult', getResult);
                    };
                    break;
                case EXPORT_SERIAL_NUMBERS_TO_CSV:
                    headers = [
                        "serial_number",
                        "part_number",
                        "sold_to",
                        "order_id", 
                        "order_line",
                        "shipping_date",
                        "extract_date"
                    ];
                
                    ordersToExport = headers.join(",") + "\n";
                
                    for (let serialNumber of action?.serialNumbers) {
                
                        let newSerialNumbersArray = [
                            serialNumber?.serial_number,
                            serialNumber?.part_number,
                            serialNumber?.sold_to,
                            serialNumber?.order_id,
                            serialNumber?.order_line,
                            serialNumber?.shipping_date,
                            serialNumber?.extract_date
                        ];
                
                        ordersToExport = ordersToExport + newSerialNumbersArray.join(",") + "\n";
                    };
                    action.CSVSerialNumbers = ordersToExport;
                    break;
                case EXPORT_ORDERS_TO_CSV:
                    headers = [
                        "order_id",
                        "order_line",
                        "order_date",
                        "part_description",
                        "part_number",
                        "sold_to", 
                    ];
                
                    ordersToExport = headers.join(",") + "\n";
                
                    for (let order of action?.orders) {
                        let newOrdersArray = [
                            order?.order_id,
                            order?.order_line,
                            order?.order_date,
                            order?.part_description,
                            order?.part_number,
                            order?.sold_to
                        ];
                
                        ordersToExport = ordersToExport + newOrdersArray.join(",") + "\n";
                    };
                    action.CSVOrders = ordersToExport;
                    break;
                case EXPORT_DATA_QUALITY_SAP_ORDERS_TO_CSV:
                    headers = [
                        "creation_date",
                        "part_number",
                        "sold_to",
                        "status",
                        "order_id",
                        "order_line",
                        "quantity",
                        "real_quantity",
                        "missing_quantity",
                    ];

                    dataQualityOrdersToExport = headers.join(",") + "\n";
                    for (let order of action?.dataQualitySapOrders) {
                        let newDataQualityOrdersArray = [
                            order?.creation_date,
                            order?.part_number,
                            order?.sold_to,
                            order?.status,
                            order?.order_id,
                            order?.order_line,
                            order?.quantity,
                            order?.real_quantity,
                            order?.missing_quantity
                        ];

                        dataQualityOrdersToExport = dataQualityOrdersToExport + newDataQualityOrdersArray.join(",") + "\n";
                    };
                    action.CSVDataQualityOrders = dataQualityOrdersToExport;
                    break;
                case FILTER_DATA_QUALITY_SAP_ORDERS:
                    break;
                case FILTER_SERIAL_NUMBERS:
                    break;
            };
            return next(action);
        }
    }
}

