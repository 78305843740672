import {
    LOADING,
    ERROR,
    SET_PARAMETERS,
    REMOVE_PARAMETER,
    CLEAR_PARAMETER,
} from '../actions';
import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from '@aws-amplify/api'
import config from '../../../aws-exports.js'
import * as queries from '../../../graphql/queries.js';

let originalFetch = require('isomorphic-fetch');
let fetch = require('fetch-retry')(originalFetch);

API.configure(config)

// TODO GLOBAL
export const restfulResources = {
    materials: '/materials',
    contracts: '/contracts',
    serialNumber: '/serial_number',
    order: '/order',
    bulkMaterials: '/materials/bulk',
    bulkContracts: '/contracts/bulk',
    placeholderMaterials: '/materials-placeholder',
    systems: '/systems',
    systemApplications: '/system_applications',
    licenses: '/licenses',
    dataQualitySapOrders: '/data_quality_sap_order',
};


// TODO GLOBAL
export async function getTokens(refresh) {
    console.log('Retrieving tokens...');
    let refreshSession = await Auth.currentSession();
    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': 'Bearer ' + refreshSession.accessToken.jwtToken
    };

    const requestBody = {};

    if (refresh) {
        console.log('Refreshing tokens');
        requestBody.grant_type = 'refresh_token';
        const refreshToken = localStorage.getItem('se-refresh-token');
        requestBody.refresh_token = refreshToken;
    } else {
        console.log('Getting new tokens');
        requestBody.app_id = 'se-admin';
        requestBody.app_secret = 'app_secret';
        requestBody.grant_type = 'client_credentials';
    }

    const url = new URL(process.env.REACT_APP_TOKEN_URL);

    try {
        // Attempt to retrieve tokens
        const response = await fetch(url, {
            method: 'POST', // Change method to POST
            headers: headers,
            body: JSON.stringify(requestBody) // Serialize the request body as JSON
        });
        if (response?.status > 200) {
            localStorage.removeItem('se-access-token');
            localStorage.removeItem('se-refresh-token');
        } else {
            const data = await response.json();
            localStorage.setItem('se-access-token', data.access_token);
            localStorage.setItem('se-refresh-token', data.refresh_token);
        }
    } catch (e) {
        // Tokens were invalid (exp)
        localStorage.removeItem('se-access-token');
        localStorage.removeItem('se-refresh-token');
        console.log('e', e);
    }
};

// TODO GLOBAL
export function parseDynamoResult(data) {
    const parsedData = {};

    for (const key in data) {
        const valueObj = data[key];
        const value = Object.values(valueObj)[0];
        parsedData[key] = value;
    }
    return parsedData;
};

// TODO GLOBAL
export async function getFromServer(questObject, method) {
    let restfulResource = questObject.resource;
    let params = questObject.params;
    let responseCode = 0;

    let refreshSession = await Auth.currentSession();
    let accessToken = localStorage.getItem('se-access-token');
    let refreshToken = localStorage.getItem('se-refresh-token');
    if (!localStorage.getItem('se-access-token')) {
        console.log('No token')
        await getTokens(false);
        accessToken = localStorage.getItem('se-access-token');
        refreshToken = localStorage.getItem('se-refresh-token');
    }

    let _url;
    if (restfulResource == 'order' || restfulResource == 'serialNumber' || restfulResource == 'dataQualitySapOrders') {
        _url = process.env.REACT_APP_SE_PROXY_URL + restfulResources[restfulResource];
    } else {
        _url = process.env.REACT_APP_SE_URL + restfulResources[restfulResource];
    }

    if (params && params.id) {
        _url += '/' + params.id;
    } else if (params) {
        let keys = Object.keys(params);
        _url += '?';
        for (let i in keys) {
            let key = keys[i];
            let value = params[key];
            if (Array.isArray(value)) {
                for (let j in value) {
                    _url += '&' + key + '=' + value[j];
                }
            } else {
                _url += '&' + key + '=' + value;
            };
        };
    };
    console.log(_url, '_url');
    let reply = await fetch(_url, {
        // method: method ? "DELETE" : "GET",
        method: "GET",
        // credentials: "same-origin", // send cookies
        headers: {
            // 'authorization': 'Bearer ' + accessToken,
            'x-api-key': 'Bearer ' + accessToken,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
    })
        .then(function (response) {
            responseCode = response.status;
            console.log('middleware response', response);
            return response.json();

        })
        .then(function (json) {
            console.log("getFromServer then", json);
            return json;
        }).catch(err => {
            responseCode = 500;
            console.log('err', err);
            return { error: 'timeout', responseCode };
        });
    if (responseCode === 401 && reply.code === 'exp' && !questObject.retry) {
        questObject.retry = true;
        return await getFromServer(questObject);
    } else if (responseCode === 500 && !questObject.retry) {

        if (localStorage.getItem('se-refresh-token')) {
            // localStorage.removeItem('se-access-token');
            // localStorage.removeItem('se-refresh-token');
            questObject.retry = true;
            await getTokens(true);
            accessToken = localStorage.getItem('se-access-token');
            refreshToken = localStorage.getItem('se-refresh-token');
            return await getFromServer(questObject);
        } else {
            await getTokens(false);
        }
    } else {
        reply.responseCode = responseCode;
        return reply;
    };
};

// TODO GLOBAL
export async function getFromGraphQlApi(query, body) {
    try {
        const args = {};
        for (const [key, value] of Object.entries(body)) {
            args[key] = value;
        }
        const response = await API.graphql(
            graphqlOperation(query, args, process.env.REACT_APP_GRAPHQL_KEY)
        );
        return response;
    } catch (err) {
        console.log(err);
    }
}


export function middleware({ dispatch }) {
    return function (next) {
        return async function (action) {
            switch (action.type){
                case LOADING:
                    break;
                default:
                    dispatch({ type: LOADING, loading: true });
                    break;

            };
            return next(action);
        }
    }
}

