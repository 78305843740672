import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation
} from "react-router-dom";
import { getOrders } from '../redux/dynamo_db/actions';
import Customer from './tables/customer';
import Order from './tables/order';


export default function OrderDetails() {

    const dispatch = useDispatch();
    const location = useLocation();
    let orderId = location.pathname.split("/")[location.pathname.split("/").length - 2];
    let orderLine = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const loading = useSelector((state) => state.loading);
    const errors = useSelector((state) => state.errors);

    // The parse/stringify cheap cloning trick is to create a local copy of the state object
    let order = useSelector((state) => {
        let _order = state?.orders?.find(c => c.order_id === orderId);
        if (_order) {
            return JSON.parse(JSON.stringify(_order));
        } else return {}
    });

    return (<>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Order />
            <div
                style={{ marginLeft: '1%' }}
            />
            <Customer />
        </div>
    </>)
}
