import {
    GET_CONTRACTS,
    GET_CONTRACT_DETAILS,
    SORT_CONTRACTS,
    GET_CONTRACTS_BULK,
    FILTER_CONTRACTS,
    EXPORT_CONTRACT_MATERIALS_TO_CSV,
    EXPORT_CONTRACTS_TO_CSV
} from '../actions';
import {LOADING} from '../../global/actions'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Papa from 'papaparse';
// import initialState from '../../global/reducer';
import {download, getNestedValueFromString, downloadBulkResult} from '../../global/reducer';

// TODO GLOBAL
const initialState = {
    parameters: {
        materials: {},
        contracts: {},
        orders: {},
        placeholderMaterials: {},
        serialNumbers: {},
        systems: {},
        systemApplications: {},
        licenses: {},
        dataQualitySapOrders: {},
    },
};

function contractReducer(state = initialState, action) {
    if (action?.type !== "SET_USER_SESSION") {
    };
    switch (action.type){
        case LOADING:
            break;
        default:
            state.loading = false;
            break;
    };
    if (state.errors) {
        delete state.errors;
    };
    state.errors = action.errors;
    switch (action.type) {
        case GET_CONTRACTS:
            state = Object.assign({}, state, { contracts: action.results, filteredContracts: action.results });
            return state;
        case GET_CONTRACT_DETAILS:
            if (!state.contracts) {
                state.contracts = [];
            };
            if (action.results && action.results.length) {

                let contractIdentifier = action.data.contract_reference + action.data.type;
                let contract = state.contracts?.find(contract => contract.contract_identifier === contractIdentifier);

                contract.entitlements = action.results;

                state.contracts = state.contracts.filter(m => m.contract_identifier !== contract.contract_identifier);
                state.contracts.push(contract);
            };
            state = Object.assign({}, state, { loading: false });
            return state;
        case FILTER_CONTRACTS:
            if (!state.contracts) {
                state.contracts = [];
            };
            let filterContractParams = action.data;
            let filteredContracts = [];

            let newContracts = state.contracts.filter(c => {
                if (filterContractParams?.contractReference?.length > 0) {
                    for (let contractReference of filterContractParams?.contractReference) {
                        if (!c?.contract_reference.startsWith(contractReference)) {
                            return false;
                        };
                    };
                }
                if (filterContractParams?.type?.length > 0) {
                    for (let type of filterContractParams?.type) {
                        if (!c?.type.startsWith(type)) {
                            return false;
                        };
                    };
                }
                if (filterContractParams?.soldTos?.length > 0) {
                    for (let soldTo of filterContractParams?.soldTos) {
                        if (!c?.sold_to?.applicant_id.startsWith(soldTo)) {
                            return false;
                        };
                    };
                }
                if (filterContractParams?.status?.length > 0) {
                    for (let status of filterContractParams?.status) {
                        if (!c?.status.startsWith(status)) {
                            return false;
                        };
                    };
                }
                if (filterContractParams?.filterCreationDateStart) {
                    if (!moment(filterContractParams.filterCreationDateStart).isBefore(moment(c.creation_date))) {
                        return false
                    }
                };
                if (filterContractParams?.filterCreationDateEnd) {
                    if (!moment(filterContractParams.filterCreationDateEnd).isAfter(moment(c.creation_date))) {
                        return false
                    }
                };
                if (filterContractParams?.filterModificationDateStart) {
                    if (!moment(filterContractParams.filterModificationDateStart).isBefore(moment(c.modification_date))) {
                        return false
                    }
                };
                if (filterContractParams?.filterModificationDateEnd) {
                    if (!moment(filterContractParams.filterModificationDateEnd).isAfter(moment(c.modification_date))) {
                        return false
                    }
                };
                return true
            });
            filteredContracts = filteredContracts.concat(newContracts)

            state = Object.assign({}, state, { filteredContracts });
            return state;

        case EXPORT_CONTRACT_MATERIALS_TO_CSV:
            state = Object.assign({}, state, { CSVMaterials: action.CSVMaterials });
            download(uuidv4() + ".csv", action.CSVMaterials);
            return state;
        case EXPORT_CONTRACTS_TO_CSV:
            state = Object.assign({}, state, { CSVContracts: action.CSVContracts });
            download(uuidv4() + ".csv", action.CSVContracts);
            return state;
        case SORT_CONTRACTS:
            const contractParam = action.data.sortParameter;
            const contractDirection = action.data.direction;

            // This makes a direct modification to the state, so no Object.assign is required. Somehow.
            state?.filteredContracts?.sort(function (a, b) {
                const valueA = getNestedValueFromString(a, contractParam);
                const valueB = getNestedValueFromString(b, contractParam);

                if (typeof valueA[0] === 'number' && typeof valueB[0] === 'number') {
                    if (contractDirection === 'ascending') {
                        return valueA - valueB;
                    } else {
                        return valueB - valueA;
                    }
                } else {
                    const textA = valueA?.toString()?.toUpperCase();
                    const textB = valueB?.toString()?.toUpperCase();

                    if (contractDirection === 'ascending') {
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    } else {
                        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                    }
                }
            });
            return state;
        case GET_CONTRACTS_BULK:
            const parsedContracts = Papa.unparse(action.bulkResponse, { header: true });
            const encodedContracts = new TextEncoder().encode(parsedContracts);
            const contractsBlob = new Blob([encodedContracts], { type: 'text/csv' });
            downloadBulkResult(contractsBlob, uuidv4(), '.csv');
            state = Object.assign({}, state);
            return state;
        default:
            return state;
    }
}

export default contractReducer

